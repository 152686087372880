import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserSelectorComponent} from '../shared/user-selector/user-selector.component';
import {IzbiraLokComponent} from '../popis-lokomotiv/shared/izbira-lok/izbira-lok.component';
import {MaterialModule} from '../shared/material.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    UserSelectorComponent,
    IzbiraLokComponent,
    ],
  imports: [ CommonModule, MaterialModule, RouterModule],
  exports: [
    UserSelectorComponent,
    IzbiraLokComponent
  ],
  providers: [],
})
export class SharedComponentsModule {}
