import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConstsService } from './consts.service';

Injectable();

@Injectable({
  providedIn: 'root',
})
export class UserService {
  get currentRole(): string {
    return this._currentRole;
  }

  set currentRole(value: string) {
    this._currentRole = value;
  }
  private _id = -1;
  private _aspId = -1;
  private _name = '';
  // aktiven field Null - zaposleni, 0 - pogodbeniki, 99 - bivši zaposleni
  private _aktiven = 99;
  private _osebnaStevilka = 0;
  private _userRoles: string[] = [];
  private _currentRole = '';
  private _token = '';

  constructor( private http: HttpClient, private consts: ConstsService, private router: Router) {
    // todo: get the real user after auth is implemented
  }

  public getUserFromZaposleniObject(zaposleniObj: User) {
    this._id = zaposleniObj.Id;
    this._aspId = zaposleniObj.AspNetUsersId;
    // mogoče bi bilo pametno obrnit da bo PriimekIme
    this._name = zaposleniObj.ImePriimek.trim();
    this._userRoles = zaposleniObj.Roles.map(r => r.Role);
    this._osebnaStevilka = zaposleniObj.OsebnaStevilka;
    if (zaposleniObj.Aktiven) {
      this._aktiven = zaposleniObj.Aktiven;
    }
    const storedRole = this.getStoredRole();
    if (!storedRole) {
      if (this._userRoles.includes('Obracun') && this._aspId !== 2) {
        this._currentRole = 'Obracun';
        localStorage.setItem('role', 'Obracun');
      } else if (this._userRoles.includes('PogledSezana')) {
        this._currentRole = 'PogledSezana';
        localStorage.setItem('role', 'PogledSezana');
      } else if (this._userRoles.includes('PogledStrojevodja')) {
        this._currentRole = 'PogledStrojevodja';
        localStorage.setItem('role', 'PogledStrojevodja');
      } else if (this._userRoles.includes('PogledKoper')) {
        this._currentRole = 'PogledKoper';
        localStorage.setItem('role', 'PogledKoper');
      }
    }
}

  get aspId(): number {
    return this._aspId;
  }

  set aspId(value: number) {
    this._aspId = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get aktiven(): number {
    return this._aktiven;
  }

  set aktiven(value: number) {
    this._aktiven = value;
  }

  get osebnaStevilka(): number {
    return this._osebnaStevilka;
  }

  set osebnaStevilka(value: number) {
    this._osebnaStevilka = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }


  get token(): string {
    return this._token;
  }

  set token(tkn: string) {
    this._token = tkn;
  }

  get userRoles(): string[] {
    return this._userRoles;
  }

  public setUserRoles(role: string) {
    this._userRoles.push(role);
  }

  private getStoredRole(): boolean {
    const r = localStorage.getItem('role');
    if (r) {
      if (this._userRoles.includes(r)) {
        this._currentRole = r;
        return true;
      }
    }
    return false;
  }

  // preveri če je user v service drugače zapre zavihek
  public async checkUser() {
    if (this._id === -1) {
      // router navigate to loading al processing
      this.router.navigate(['loading'])
      if (document.cookie.length > 0) {
        console.log('cookies exist');
        let userId = localStorage.getItem('c3');
        this.token = localStorage.getItem('tkn');
        // debug stuff
        // const delay = ms => new Promise(res => setTimeout(res, ms));
        // await delay(5000);

        // get user object and return early
        await this.http.get(this.consts.domena + 'Zaposlenis/GetZaposleni/' + userId).toPromise()
          .then((res: User[]) => {
            if (res.length === 1) {
              this.getUserFromZaposleniObject(res[0]);
              console.log('YAHOO2::', this.name);
              this.router.navigate(['']);
              // early return or close window
              return 1;
            }
          });
      }
      if (this._id === -1) {
        window.close();
      }
    }
  }

  public getUserObj() {
      const obj: User = {
        Id: this._id,
        ImePriimek: this._name,
        OsebnaStevilka: this._osebnaStevilka,
        AspNetUsersId: this._aspId,
        /*Roles: this._userRoles,*/
        Aktiven: this._aktiven
      }
      return obj;
  }

}

// TODO: popravi kar se bo vse uporabljalo pri useru delovnoMEsto in take zadeve
export interface User {
  Id: number;
  ImePriimek: string;
  OsebnaStevilka?: number;
  AspNetUsersId: number;
  Roles?: Role[];
  Aktiven?: number;
}

interface Role {
  Role: string;
}
