<mat-menu #appMenu="matMenu">
  <!--  TODO: naredi različne backgrounde sz theming scss-->
  <button mat-menu-item (click)="changeFilter()">{{filterDloks ? "*vse*" : "*Dloks*"}}</button>
  <button mat-menu-item *ngFor="let lok of loksToShow" (click)="navigateToLocomotive(lok.ime)"
          [ngClass]="{
    'vectron':lok.ime.includes('193'),
    'taurus': lok.ime.includes('1216'),
    'hercules': lok.ime.includes('2016')}">
    {{lok.ime}}
  </button>
</mat-menu>

<button mat-raised-button [matMenuTriggerFor]="appMenu">
  Lokomotiva
</button>
