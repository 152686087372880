import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-prijava',
  templateUrl: './prijava.component.html',
  styleUrls: ['./prijava.component.scss']
})
export class PrijavaComponent implements OnInit {

  constructor(title: Title, private auth: AuthService) {
    title.setTitle('Prijava');
  }

  ngOnInit() {
  }

  login() {
    this.auth.login();
    this.auth.redirectUserToLandingPage();
  }

}
