import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NapakaComponent } from '../../core/napaka/napaka.component';
import { PrijavaComponent } from '../../core/prijava/prijava.component';
import { NavBarComponent } from '../../nav-bar/nav-bar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PrintPopisiEvidencaComponent } from 'src/app/shared/print/print-popisi-evidenca/print-popisi-evidenca.component';
import { PrintComponent } from 'src/app/shared/print/print/print.component';
import { UrlLoginComponent } from '../../shared/url-login/url-login.component';
import { PrintNepravilnostComponent } from '../../shared/print/print-nepravilnost/print-nepravilnost.component';
import { PrintIzrednostComponent } from '../../shared/print/print-izrednost/print-izrednost.component';
import { PrintDpComponent } from 'src/app/shared/print/print-dp/print-dp.component';
import { PrintEvidencaVoznjeComponent } from 'src/app/shared/print/print-evidenca-voznje/print-evidenca-voznje.component';
import { PrintEvidencaObracunComponent } from 'src/app/shared/print/print-evidenca-obracun/print-evidenca-obracun.component';
import { PrintPotniStroskiComponent } from 'src/app/shared/print/print-potni-stroski/print-potni-stroski.component';
import { LoadingComponent } from 'src/app/shared/loading/loading.component';

// TODO: resturcture routes to be module based and handle auth in auth.service and guards
const routes: Routes = [
  {
    path: '', component: NavBarComponent, children: [
      { path: '', redirectTo: 'popis-lokomotiv', pathMatch: 'full' },
      {
        path: 'popis-lokomotiv',
        loadChildren: () => import('../../popis-lokomotiv/popis-lokomotiv.module').then(m => m.PopisLokomotivModule)
      },
      { path: 'turnus', loadChildren: () => import('../../turnus/turnus.module').then(m => m.TurnusModule) },
      { path: 'dopusti', loadChildren: () => import('../../dopusti/dopusti.module').then(m => m.DopustiModule) },
      { path: 'porocila', loadChildren: () => import('../../porocila/porocila.module').then(m => m.PorocilaModule) },
      { path: 'rekap', loadChildren: () => import('../../rekapitulacija/rekapitulacija.module').then(m => m.RekapitulacijaModule) },
      { path: 'dnevni-pregledi',
        loadChildren: () => import('../../dnevni-pregledi/dnevni-pregledi.module').then(m => m.DnevniPreglediModule) },
      { path: 'evidence', loadChildren: () => import('../../evidence/evidence.module').then(m => m.EvidenceModule) },
      { path: 'urniki', loadChildren: () => import('../../urniki/urniki.module').then(m => m.UrnikiModule) },
    ]
  },
  { path: 'prijava', component: PrijavaComponent },
  { path: 'url-login', component: UrlLoginComponent },
  { path: 'loading', component: LoadingComponent },
  {
    path: 'print',
    outlet: 'print',
    component: PrintComponent,
    children: [
      { path: 'popisi-lok-evidenca', component: PrintPopisiEvidencaComponent },
      { path: 'izrednost', component: PrintIzrednostComponent },
      { path: 'nepravilnost', component: PrintNepravilnostComponent },
      { path: 'dnevni-pregled', component: PrintDpComponent },
      { path: 'evidenca-obracun', component: PrintEvidencaObracunComponent },
      { path: 'evidenca-voznje', component: PrintEvidencaVoznjeComponent },
      { path: 'potni-stroski', component: PrintPotniStroskiComponent },
    ]
  },
  // TODO: kle dej na /prijava za prod
  { path: '', redirectTo: '/prijava', pathMatch: 'full' },
  { path: '**', component: NapakaComponent },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'}),
  // kle je useHash, da lohko spreminjam url brez da server loada ta path
  // (for production izbriši ko erik implementira servanje angular appa)
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true, relativeLinkResolution: 'legacy' }),
    MatTabsModule,
  ],
  declarations: [],
  exports: [RouterModule]
})
export class RoutingModule {
}
