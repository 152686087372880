import { Component, OnInit } from '@angular/core';
import { IDnevniPregled } from 'src/app/dnevni-pregledi/dnevni-pregledi.service';
import { ConstsService } from '../../consts.service';
import { PrintService } from '../print.service';

@Component({
  selector: 'app-print-dp',
  template: `
<div align="left">
  <img [src]="consts.domena + 'Content/pics/Logo_Adria_Transport.png'" height="30px">
</div>

<div>
  <h4 align="center" *ngIf="data.LokType === 0">DNEVNI PREGLED VOZILA E-LOK</h4>
  <h4 align="center" *ngIf="data.LokType === 1">DNEVNI PREGLED VOZILA D-LOK</h4>
<!--   <div align="center" class="flex">
    <p>Dnevni pregled vozila</p>
    <p>E-lok</p>
  </div> -->
  <div *ngIf="data.LokType === 0" style="width: 600px; margin-top: 10px; margin-bottom: 10px;">
    <span style="margin-left: 230px; margin-right: 60px;">Dnevni pregled vozila</span> <span>E-lok</span>
  </div>
  <div *ngIf="data.LokType === 1" style="width: 600px; margin-top: 10px; margin-bottom: 10px;">
    <span style="margin-left: 230px; margin-right: 60px;">Dnevni pregled vozila</span> <span>D-lok</span>
  </div>

  <div style="display: flex; justify-content: space-around">
    <div>Štev.: <u>{{data.StLok}}</u></div>
    <div>Datum: <u>{{data.Datum | date: "dd.MM.yyyy"}}</u></div>
    <div>Kraj: <u>{{data.Kraj}}</u></div>
  </div>
  <div class="padding" style="margin: 10px 42px;">Ime in priimek: <u>{{data.ImePriimek}}</u></div>
  <div class="flex">
    <div>
      <div style="display:flex; justify-content: space-between;">
        <div>Zavorni del:</div> 
        <div>ni v redu</div> 
      </div>
      <div class="flex2">
        <div>- delovanje zavore</div>
        <input type="checkbox" [checked]="data.DelovanjeZavore" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- zavornjaki</div>
        <input type="checkbox" [checked]="data.Zavornjaki" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- ročna zavora</div>
        <input type="checkbox" [checked]="data.RocnaZavora" value="value" class="dnevniCheck">
      </div>
      <br>
      <div style="display:flex; justify-content: space-between;">
        <div>Mehanski del:</div> 
      </div>
      <div class="flex2" *ngIf="data.LokType === 1">
        <div>- dizelski motor</div>
        <input type="checkbox" [checked]="data.DizelskiMotor" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- odbojne in vlečne naprave</div>
        <input type="checkbox" [checked]="data.OdbojneInVlecneNaprave" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- tekalo-podstavni vozički</div>
        <input type="checkbox" [checked]="data.Tekalo" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- karterji, obesni ležaji</div>
        <input type="checkbox" [checked]="data.KrterjiLezaji" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- vzmetenje</div>
        <input type="checkbox" [checked]="data.Vzmetenje" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- kompresor</div>
        <input type="checkbox" [checked]="data.Kompresor" value="value" class="dnevniCheck">
      </div>
      <br>
      <div style="display:flex; justify-content: space-between;">
        <div>Električni del:</div> 
      </div>
      <div class="flex2">
        <div>- vlečni tokokrog</div>
        <input type="checkbox" [checked]="data.VlecniTokokrog" value="value" class="dnevniCheck">
      </div>
      <div class="flex2" *ngIf="data.LokType === 0">
        <div>- odjemnik toka</div>
        <input type="checkbox" [checked]="data.OT" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- inštrumenti</div>
        <input type="checkbox" [checked]="data.Instrumenti" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- čelne in sklepne signalne luči</div>
        <input type="checkbox" [checked]="data.SignalneLuci" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- ogrevanje kabine</div>
        <input type="checkbox" [checked]="data.OgrevanjeKabine" value="value" class="dnevniCheck">
      </div>
    </div> 
    <div>
      <div style="display:flex; justify-content: space-between;">
        <div>Varnostne naprave:</div> 
        <div>ni v redu</div> 
      </div>
      <div class="flex2">
        <div>- <b>ASN</b></div>
        <input type="checkbox" [checked]="data.ASN" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- budnik</div>
        <input type="checkbox" [checked]="data.Budnik" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- merilnik hitrosti</div>
        <input type="checkbox" [checked]="data.MerilnikHitrosti" value="value" class="dnevniCheck">
      </div>
      <br>
      <div style="display:flex; justify-content: space-between;">
        <div>Drugo:</div> 
      </div>
      <div class="flex2">
        <div>- pomožne naprave</div>
        <input type="checkbox" [checked]="data.PomozneNaprave" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- <b>RDZ</b></div>
        <input type="checkbox" [checked]="data.RDZ" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- signalna sirena</div>
        <input type="checkbox" [checked]="data.SignalnaSirena" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- brisalci stekel</div>
        <input type="checkbox" [checked]="data.BrisalciStekel" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- plombe</div>
        <input type="checkbox" [checked]="data.Plombe" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- oprema</div>
        <input type="checkbox" [checked]="data.Oprema" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- čistost kabine</div>
        <input type="checkbox" [checked]="data.CistostKabine" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- čistost zunanjosti lokomotive</div>
        <input type="checkbox" [checked]="data.CistostZunaj" value="value" class="dnevniCheck">
      </div>
      <div class="flex2">
        <div>- klimatska naprava kabine</div>
        <input type="checkbox" [checked]="data.Klima" value="value" class="dnevniCheck">
      </div>
      <div class="flex2" *ngIf="data.LokType === 1">
        <div>- pogonski materiali (nafta, hladilna tekočina, pesek, olja)</div>
        <input type="checkbox" [checked]="data.PogonskiMateriali" value="value" class="dnevniCheck">
      </div>
      <div class="flex2" *ngIf="data.LokType === 0">
        <div>- pogonski materiali (pesek, olja)</div>
        <input type="checkbox" [checked]="data.PogonskiMateriali" value="value" class="dnevniCheck">
      </div>
    </div>
  </div>
  <div style="margin-top: 45px;" *ngIf="data.LokType === 0"></div>
  <div style="margin-top: 20px;" *ngIf="data.LokType === 1"></div>
  <div style="width: 370px; padding: 0 20px;">
    <div style="float: right;">
      <b>DA - NE</b>
    </div>
    <br>
    <div class="flex2">
      <div>Lokomotiva sposobna za nadaljno vožnjo</div>
      <div>
        <input type="checkbox" [checked]="data.LokSposobnaNe" value="value" style="float: right; margin-right: 3px">
        <input type="checkbox" [checked]="data.LokSposobnaDa" value="value" style="float: right; margin-right: 18px">
      </div>
    </div>
    <div class="flex2">
      <div>Lokomotivo je treba dostaviti v popravilo</div>
      <div>
        <input type="checkbox" [checked]="data.LokPopraviloNe" value="value" style="float: right; margin-right: 3px">
        <input type="checkbox" [checked]="data.LokPopraviloDa" value="value" style="float: right; margin-right: 18px">
      </div>
    </div>
  </div>
  <div class="padding" style="margin-top: 8px;" *ngIf="data.LokType === 1">
    <div>Stanje goriva po končani službi: <b>{{data.StanjeGorivaPoSluzbi}}{{data.StanjeGorivaPoSluzbi ? " l" : ""}}</b></div>
    <div>Količina dolitega olja v DM: <b>{{data.KolicinaDolitegaOlja}}{{data.KolicinaDolitegaOlja ? " l" : ""}}</b></div>
    <div>Količina dolite hladilne tekočine: <b>{{data.KolicinaDoliteHladilneTekocine}}{{data.KolicinaDoliteHladilneTekocine ? " l" : ""}}</b></div>
  </div>
  <div class="padding" style="margin-top: 15px;">
    <div>
      Opombe:
    </div>
    <div style="margin-top: 10px; display: flex; justify-content: space-around;">
      <textarea matInput rows="7" [(ngModel)]="data.Opombe" class="textarea"></textarea>
    </div>
  </div>
  <hr style="margin: 10px;">
  <div class="padding">Popolni preizkus zavore uspešno opravljen dne: {{data.Datum | date: "dd.MM.yyyy HH:mm" }}</div>
  <div class="padding" style="margin-top: 20px;">Ime Priimek: {{data.ImePriimek}} <span style="margin-left: 215px;">Podpis: 
  <img [src]="consts.domena + 'Content/podpisi/zaposleni_id_' + data.ZaposleniId + '.png'" alt=""></span></div>
  <div style="display: flex; justify-content: flex-end">
    <div *ngIf="data.LokType === 0" style="margin-right: 30px;">EVVD 12/1</div>
    <div *ngIf="data.LokType === 1" style="margin-right: 30px;">EVVD 12/2</div>
  </div>
  <div class="padding" style="margin-top: 20px; font-size: 9px;">OPOMBA: Vozilo mora biti praviloma vsaj enkrat na dan pregledano. Pregled mora biti opravljen, če traja postanek med vlaki več kot 1 uro. Pregled posameznih naprav se označi s kljukico ali križcem.</div>
</div>
  `,
  styles: [
    `
    .flex{
      display: grid;
      grid-template-columns: 45% 45%;
      justify-content: space-around;
      padding: 0 20px;
    }
    .flex2{
      display: flex;
      justify-content: space-between;
    }
    .dnevniCheck{
      margin-right: 25px;
    }
    .padding{
      padding: 0 20px;
    }
    .textarea{
      border: 1px solid #000;
      border-radius: 4px;
      padding: 6px 12px;
      width: 60%;
      font-size: 11px;
    }
    `
  ]
})
export class PrintDpComponent implements OnInit {

  public data: IDnevniPregled;
  constructor(public consts: ConstsService, private printService: PrintService) { }


  ngOnInit(): void {
    this.data = this.printService.dpData;
  }

}
