<mat-toolbar color="primary">
  <span>Adria Transport</span>
</mat-toolbar>
<div>
  <form class="prijava">
    <p>
        <mat-form-field appearance="outline" class="forma">
            <mat-label>Uporabniško ime</mat-label>
            <input matInput>
            <mat-icon matSuffix>person</mat-icon>
          </mat-form-field>
    </p>     
    <p>
        <mat-form-field appearance="outline" class="forma">
            <mat-label>Geslo</mat-label>
            <input matInput type="password">
            <mat-icon matSuffix>lock</mat-icon>
          </mat-form-field>
    </p>
    <button class="forma" mat-raised-button color="primary" (click)="login()">Prijava</button>
  </form>
</div>