import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AllZaposleniResponse, Zaposleni } from './_models';
import { HttpClient } from '@angular/common/http';
import { ConstsService } from '../consts.service';
import { ConversionService } from '../conversion.service';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit {
  public date: Date = new Date();
  public dateTo: Date;
  public stringInput: string;
  public monthSelection: number;

  constructor(private http: HttpClient, private consts: ConstsService, private conversion: ConversionService) { }
  myControl = new FormControl();
  allZaposleni: Zaposleni[] = [];
  shownZaposleni: Zaposleni[] = [];
  filteredZaposleni: Observable<Zaposleni[]>;
  @Input() showOnlyStrojevodje = false;
  @Input() showZaposlene = true;
  @Input() showDatum = true;
  @Input() showDatumTo = false;
  @Input() showMonthSelect = false;
  @Input() showStringInput = false;
  // izbran zaposlen (ID)
  @Input() selectedZaposleni: Zaposleni = { AspNetUsersId: -1, Id: -1, ImePriimek: '', OsebnaStevilka: -1 };
  @Output() outEvent = new EventEmitter<{zaposleni: Zaposleni, date: Date, dateTo: Date, stringValue: string, monthSelection: number}>();

  // ali pokaže tudi nekativne zaposlene
  showAll = false;

  async ngOnInit() {
    if (this.showZaposlene) {
      await this._getAllZaposleni().then(res => {
        this.allZaposleni = res.Result;
        this.allZaposleni.map(zap => zap.ImePriimek = this.conversion.toPriimekIme(zap.ImePriimek));
        this.allZaposleni.sort(this.conversion.dynamicSort('ImePriimek'));
      });
      this.filterZaposlene();
    }
    if (this.selectedZaposleni.Id !== -1) {
        this.myControl.setValue(this.selectedZaposleni.ImePriimek);
    }
  }
  filterZaposlene() {
    this.shownZaposleni = this._filterByType(this.allZaposleni.slice());
    if (!this.showAll) {
      this.shownZaposleni = this.shownZaposleni.filter(zap => zap.Aktiven !== 99);
    }
    this.filteredZaposleni = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  private _filterByType(arr: Zaposleni[]): Zaposleni[] {
    if (this.showOnlyStrojevodje) {
      return arr.filter(zap => zap.OsebnaStevilka !== null);
    }
    return arr;
}
  private _filter(value: string): Zaposleni[] {
    const filterValue = value.toLowerCase();

    return this.shownZaposleni.filter(zaposleni => zaposleni.ImePriimek.toLowerCase().includes(filterValue));
  }
  private _getAllZaposleni(): Promise<AllZaposleniResponse> {
    return this.http.get<AllZaposleniResponse>( this.consts.domena + '/Zaposlenis/GetAllZaposleni/').toPromise();
  }
  clearZaposlenega() {
    this.myControl.setValue('');
    this.selectedZaposleni = { AspNetUsersId: -1, Id: -1, ImePriimek: '', OsebnaStevilka: -1 };
  }
  izberi() {
    // VALIDACIJA
    if (this.selectedZaposleni.Id === -1 && this.showZaposlene) {
      alert('Ni izbran nobeden zaposleni!!!');
      return -1;
    }
    if (this.showMonthSelect && this.monthSelection === undefined) {
      alert('Potrebno je izbrati mesec');
    }
    this.outEvent.emit({ zaposleni: this.selectedZaposleni, date: this.date, dateTo: this.dateTo, stringValue: this.stringInput,
    monthSelection: Number(this.monthSelection) });
  }


  teest() {
    console.log(this.selectedZaposleni);
    console.log(this.showAll);
    console.log(this.showOnlyStrojevodje);
  }
}
