<mat-sidenav-container
  class="sidenav-container"
  [class.stran]="printService.isPrinting"
>
<!-- [opened has some weird stuff]-->
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)"
  >
    <mat-toolbar color="primary">Menu</mat-toolbar>
    <mat-nav-list>

      <a mat-list-item routerLink="/urniki" (click)="navClicked()">Urniki</a>

<!--


      <a mat-list-item routerLink="/evidence" (click)="navClicked()">Evidence</a>

      <a mat-list-item routerLink="/dopusti" (click)="navClicked()">Dopusti</a>

      <mat-menu #appMenuPorocila="matMenu">
        <button mat-menu-item routerLink="/porocila/nepravilnosti">
          Nepravilnosti
        </button>
        <button mat-menu-item routerLink="/porocila/izrednosti">
          Izrednosti
        </button>
      </mat-menu>

      <a mat-list-item [matMenuTriggerFor]="appMenuPorocila">
        Poročila
      </a>-->

      <!--<mat-menu #appMenuPopisi="matMenu">
        <button
          mat-menu-item
          routerLink="/popis-lokomotiv"
          (click)="navClicked()"
        >
          Lokomotiv
        </button>
        <button
          mat-menu-item
          routerLink="/popis-vlakov"
          (click)="navClicked()"
        >
          Vlakov
        </button>
      </mat-menu>
      <a mat-list-item [matMenuTriggerFor]="appMenuPopisi">
        Popisi
      </a>-->
<!--FIXME: dokler ni še kakšnih popisov-->
      <a mat-list-item routerLink="/dnevni-pregledi" (click)="navClicked()">Dnevni Pregledi</a>
      <a mat-list-item routerLink="/popis-lokomotiv" (click)="navClicked()">Popis Lokomotiv</a>
      <a mat-list-item routerLink="/turnus" (click)="navClicked()" *ngIf="user.currentRole==='PogledStrojevodja'">Turnus</a>
      <a mat-list-item [matMenuTriggerFor]="turnusMenu" *ngIf="user.currentRole!=='PogledStrojevodja'">Turnus Menu</a>
      <a mat-list-item routerLink="/dopusti" (click)="navClicked()">Dopusti</a>
      <a mat-list-item routerLink="/dopusti/izredni" (click)="navClicked()" *ngIf="user.userRoles.includes('Obracun')">Izredni Dopusti</a>
      <a mat-list-item [matMenuTriggerFor]="popisiMenu" *ngIf="user.currentRole !== 'PogledKoper'">Poročila</a>
      <a mat-list-item routerLink="/evidence">Evidence</a>
      <a mat-list-item [matMenuTriggerFor]="rekapMenu" *ngIf="user.userRoles.includes('Obracun')">Rekap</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()">
<!--        *ngIf="isHandset$ | async">-->
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Adria Transport</span>
      <span class="seperator"></span>
      <!--<button mat-raised-button color="accent" [matMenuTriggerFor]="roleSelector" *ngIf="showRoleSelector">Role: {{user.currentRole}}</button>-->
      <span class="version">v: {{ version }}</span>
      <mat-menu #roleSelector="matMenu">
        <button mat-menu-item (click)="changeUserRole('PogledSezana')">Hq</button>
        <button mat-menu-item (click)="changeUserRole('PogledStrojevodja')">Strojevodja</button>
        <button mat-menu-item (click)="changeUserRole('PogledKoper')">Lk</button>
        <button mat-menu-item (click)="changeUserRole('Obracun')">Obracun</button>
      </mat-menu>
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="changeTheme()">Spremeni temo</button>
        <!--      TODO: for development only (until auth implemented)-->
        <button mat-menu-item [matMenuTriggerFor]="roleSelector" *ngIf="showRoleSelector">Role: {{user.currentRole}}</button>
        <button mat-menu-item (click)="close()">Zapri</button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>account_box</mat-icon>
      </button>
    </mat-toolbar>
    <!-- Add Content Here -->
    <div class="container" id="container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


<mat-menu #turnusMenu="matMenu">
  <button
    mat-menu-item
    routerLink="/turnus"
    (click)="navClicked()"
  >
    Turnus
  </button>
  <button
    mat-menu-item
    routerLink="/turnus/ture"
    (click)="navClicked()"
  >
    Ture
  </button>
</mat-menu>

<mat-menu #popisiMenu="matMenu">
  <button
    mat-menu-item
    routerLink="/porocila/izrednosti"
    (click)="navClicked()"
  >
    Izrednosti
  </button>
  <button
    mat-menu-item
    routerLink="/porocila/nepravilnosti"
    (click)="navClicked()"
  >
    Nepravilnosti
  </button>
</mat-menu>

<mat-menu #rekapMenu="matMenu">
  <button
    mat-menu-item
    routerLink="/rekap/bolniske"
    (click)="navClicked()"
  >
    Bolniške
  </button>
  <button
    mat-menu-item
    routerLink="/rekap/nadure"
    (click)="navClicked()"
  >
    Nadure
  </button>
  <button
    mat-menu-item
    routerLink="/rekap/stimulacija"
    (click)="navClicked()"
  >
    Stimulacija
  </button>
<button
    mat-menu-item
    routerLink="/rekap/potni-stroski"
    (click)="navClicked()"
  >
    Potni Stroški
</button>
</mat-menu>
