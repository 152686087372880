import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabela-rekap',
  templateUrl: './tabela-rekap.component.html',
  styleUrls: ['./tabela-rekap.component.scss']
})
export class TabelaRekapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
