import { Injectable } from '@angular/core';
import { ITrajanje } from './_models';

@Injectable()
export class ConversionService {
    toDate(s: string|Date): Date {
        if (typeof(s) !== 'string') {
            // console.log('NOT A STRING:', s);
            return s;
        }
        const t = s.match(/\d+/g);
        const d = new Date(Number(t[0]));
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    }

    calcTimeDiff(startTime, endTime): {hours: number, mins: number} {
        if (!startTime || !endTime) {
            return { hours: 0, mins: 0 };
        }
        let ms: number = this.toDate(endTime).getTime() - this.toDate(startTime).getTime();
        const diffHours = Math.floor(ms / 1000 / 60 / 60);
        ms -= diffHours * 1000 * 60 * 60;
        const diffMins = Math.floor(ms / 1000 / 60);
        return { hours: diffHours, mins: diffMins };
    }

    calcTimeDiffToString(startTime: string | Date, endTime: string | Date) {
        if (!startTime || !endTime) {
            return '/';
        }
        let ms: number = this.toDate(endTime).getTime() - this.toDate(startTime).getTime();
        const diffHours = Math.floor(ms / 1000 / 60 / 60);
        ms -= diffHours * 1000 * 60 * 60;
        const diffMins = Math.floor(ms / 1000 / 60);
        const hourStr: string = diffHours < 10 ? '0' + diffHours : String(diffHours);
        const minStr: string = diffMins < 10 ? '0' + diffMins : String(diffMins);
        return hourStr + ':' + minStr;
    }

    prikazCene(cena) {
        let outCena = cena;
        if (Number(outCena)) {
            outCena = String(outCena.toFixed(2)) + ' €';
        }
        return outCena;
    }

    toPriimekIme(name: string) {
        let newName = name.trim();
        const temp = newName.split(' ');
        if (temp[1] === undefined) {
            return temp[0];
        }
        newName = temp[1] + ' ' + temp[0];
        return newName;
    }

    dynamicSort(property) {
        let sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function(a, b) {
            const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        };
    }

    displayTrajanje(trajanje: ITrajanje, evidenca = '') {
        if (!trajanje) {
            if (evidenca == 'voznje') {
                return 'Ni prejšnjega urnika'
            }
            return '00:00';
        }
        // change mins to hours
        if (trajanje.mins > 60 ) {
            trajanje.hours += Math.floor(trajanje.mins / 60);
            trajanje.mins = trajanje.mins % 60
        }

        return `${String(trajanje.hours).padStart(2, '0')}:${String(trajanje.mins).padStart(2, '0')}`
    }

    filterStrojevodje(data) {
        return data.filter((p) => {
            let isStr = false;
            p.Roles.forEach(el => {
                if (el.Role === 'PogledStrojevodja') {
 isStr = true;
}
            });
            return isStr;
        });
    }

    filterAktivneStrojevodjeZaHq(data) {
        return data.filter((p) => {
            let isStr = false;
            let isAktiven;
            if (p.Roles) {
                p.Roles.forEach(el => {
                    if (el.Role === 'PogledStrojevodja') {
 isStr = true;
}
                });
            }
            p.Aktiven === 99 ? isAktiven = false : isAktiven = true;
            // console.log(p.ImePriimek, isAktiven, isStr);
            if (isStr && isAktiven) {
                return true;
            } else {
                return false;
            }

        });
    }

    filterAktiveZaposlene(data) {
        return data.filter((p) => {
            let isAktiven;
            p.Aktiven === 99 ? isAktiven = false : isAktiven = true;
            return isAktiven;

        });
    }

    filterAktivnePisarnaLk(data) {
        return data.filter((p) => {
            let isLk = false;
            let isAktiven;
            p.Roles.forEach(el => {
                if (el.Role === 'PogledKoper') {
 isLk = true;
}
            });
            p.Aktiven === 0 ? isAktiven = false : isAktiven = true;
            if (isLk && isAktiven) {
                return true;
            } else {
                return false;
            }

        });
    }

}
