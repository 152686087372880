import { Component, ViewChild, isDevMode, OnInit } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrintService } from '../shared/print/print.service';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawerRef;
  public version = '3.0';

  public showRoleSelector = this.user.aspId === 2 || this.user.aspId === 13;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.TabletPortrait, Breakpoints.Handset])
    .pipe(map(result => result.matches));

  navClicked() {
    if (this.drawerRef.mode === 'over') {
      this.drawerRef.opened = 'false';
    }
  }

  changeUserRole(role: string) {
    this.user.currentRole = role;
    localStorage.setItem('role', role);
  }

  changeTheme() {
    const t = localStorage.getItem('dark-theme');
    const elm = document.getElementById('theme');
    if (t === 'true') {
      localStorage.setItem('dark-theme', 'false');
      elm.classList.remove('dark-theme');
      elm.classList.add('light-theme');
    } else {
      localStorage.setItem('dark-theme', 'true');
      elm.classList.remove('light-theme');
      elm.classList.add('dark-theme');
    }
  }

  getTheme() {
    let t = localStorage.getItem('dark-theme');
    if (!t) {
      localStorage.setItem('dark-theme', 'true');
      t = 'true';
    }
    const elm = document.getElementById('theme');
    if (t === 'false') {
      elm.classList.remove('dark-theme');
      elm.classList.add('light-theme');
    } else {
      elm.classList.remove('light-theme');
      elm.classList.add('dark-theme');
    }

  }

  close() {
    window.close();
    // TODO: del cookies??
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public printService: PrintService,
    public user: UserService,
  ) {}

  ngOnInit(): void {
    this.getTheme();
  }
}
