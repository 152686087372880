import { BLACK_ON_WHITE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';
import { Component, OnInit } from '@angular/core';
import { IEvidenca } from 'src/app/evidence/evidence.service';
import { ConstsService } from '../../consts.service';
import { ConversionService } from '../../conversion.service';
import { PrintService } from '../print.service';

@Component({
  selector: 'app-print-evidenca-voznje',
  template: `
    <div style="height: 30px;">
      <img [src]="consts.domena + '/Content/pics/Logo_Adria_Transport.png'" height="25px" style="float: right;">
    </div>
    <hr>
    <div style="padding: 10px; font-size: 13px">
      <div style="margin-bottom: 5px;">
        <div style="display: inline-block;">EVIDENCA VOŽNJE ZA: {{ evidenca.datum }}</div>
        <div style="display: inline-block; margin-left: 30px;">STROJEVODJA: <b>{{ evidenca.ime}}</b></div>
      </div>
      <table class="table is-fullwidth is-bordered print" *ngIf="evidenca.urniki.length > 0">
          <th>Datum</th>
          <th>Vlak</th>
          <th>Trajanje počitka pred nastopom izmene</th>
          <th>Pričetek izmene</th>
          <th>Konec izmene</th>
          <th>Skupni čas trajanja izmene</th>
          <th>Čas prekinitev</th>
          <th>Pričetek vožnje vlaka</th>
          <th>Konec vožnje vlaka</th>
          <th>Trajanje vožnje vlaka</th>
          <th>Skupni čas premika</th>
          <th>Skupni čas vožnje vlaka in premika</th>
          <th>Nočno delo</th>
        <tbody>
          <tr *ngFor="let urnik of evidenca.urniki">
          <td>{{urnik.datum | date: 'dd.MM.yy'}}</td>
          <td>
            <div *ngFor="let vlak of urnik.vlaki">{{vlak}}</div>
          </td>
          <td>{{conversion.displayTrajanje(urnik.pocitek)}}</td>
          <td>{{urnik.nastopSluzbe | date: 'HH:mm'}}</td>
          <td>{{urnik.konecIzmene | date: 'HH:mm'}}</td>
          <td>{{conversion.displayTrajanje(urnik.trajanjeIzmene)}}</td>
          <td>{{conversion.displayTrajanje(urnik.casPrekinitev)}}</td>
          <td>{{urnik.pricetekVoznje| date: 'HH:mm'}}</td>
          <td>{{urnik.konecVoznje | date: 'HH:mm'}}</td>
          <td>{{conversion.displayTrajanje(urnik.trajanjeVoznje)}}</td>
          <td>{{urnik.premik}}</td>
          <td>{{conversion.displayTrajanje(urnik.skupnaVoznja)}}</td>
          <td>{{conversion.displayTrajanje(urnik.nocneUre)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <hr>
      <div style="display: flex; justify-content: center; font-size: 7px;">
        Adria transport, d.o.o. Vojkovo nabrežje 38 , 6000 Koper
      </div> 
    </div>
  `,
  styles: [
    `
    .print {
      color: black;
      font-size: 10px;
    }
    `
  ]
})
export class PrintEvidencaVoznjeComponent implements OnInit {

  public evidenca: IEvidenca
  constructor(private printService: PrintService, public conversion: ConversionService, public consts: ConstsService) { }

  ngOnInit(): void {
    this.evidenca = this.printService.evidencaData;
  }

}
