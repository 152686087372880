import {Injectable} from '@angular/core';
import {ConstsService} from '../shared/consts.service';
import {ConversionService} from '../shared/conversion.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {IPopisValidation, IPopisVlaka, IPostByDate, IPostByLok, IPostByVlak, IPostByYear, IPostDelete} from './shared/_models';

@Injectable({
  providedIn: 'root'
})
export class PopisLokomotivService {

  private _izbranaLok;


  constructor(private consts: ConstsService, private conversion: ConversionService, private http: HttpClient) {
  }

  public izbranaLok(lok) {
    this._izbranaLok = lok;
  }

  public getIzbranoLok() {
    return this._izbranaLok;
  }

  private _isPopisZakljucen(popis: IPopisVlaka): boolean {
    if (popis.DmKonec === null || popis.KmKonec === null || popis.DatumPrihod === null) {
      return false;
    }
  }

  // TODO: preveri če je pravilna validacija kot je zdej na produkciji
  public popisValidation(popis: IPopisVlaka): IPopisValidation {
    let valid = true;
    const errors: string[] = [];

    // validacija za LK
    if (popis.Vrsta === '1') {
      if (!popis.DatumOdhod) { errors.push('\nManjka čas'); }
      if (!popis.GorivoKm) { errors.push('\nManjka polje Km'); }
      if (!popis.GorivoDm) { errors.push('\nManjka polje Dizel motor'); }
      if (!popis.GorivoNatoceno) { errors.push('\nManjka polje Količina'); }
      if (errors.length > 0) {valid = false; }
      return {valid: valid, errors: errors};
    }

    if (!popis.Vlak) { errors.push('\nManja vlak'); }

    // če so km ali dm ali gorivo more bit prihod, da gre v naslednjo validacijo (če so vsi podatki pravilni)
    if (popis.KmKonec || popis.DmKonec || popis.GorivoKonec) {
      console.log('datuprihh:', popis.DatumPrihod, typeof popis.DatumPrihod);
      if (!popis.DatumPrihod) { errors.push('\nNi prihoda'); }
    }

    if (popis.DatumPrihod) {
      // če je prihod morajo biti vsi osnovni podatki vneseni
      if (!popis.DmKonec && popis.LokType === '1') {
        if (!popis.DmZacetek) { errors.push('\nZačetek Dm manjka'); }
        errors.push('\nKonec Dm manjka');
      }
      if (!popis.KmKonec) {
        if (!popis.KmZacetek) { errors.push('\nZačetek Km manjka'); }
        errors.push('\nKonec Km manjka');
      }
      if (!popis.GorivoKonec && popis.LokType === '1') {
        if (!popis.GorivoZacetek) { errors.push('\nZačetek Gorivo manjka'); }
        errors.push('\nKonec Gorivo manjka');
      }
      // odhod mora biti pred prihodom (če je seveda vnešen)
      if (popis.DatumOdhod) {
        if (popis.DatumOdhod > popis.DatumPrihod) { errors.push('\nPrihod popisa je pred odhodom.'); }
      }
      if (+popis.KmKonec - +popis.KmZacetek < 0) { errors.push('\nSeštevek kilometrov je manjši od 0'); }
      if (+popis.KmKonec - +popis.KmZacetek > 500) { errors.push('\nSeštevek kilometrov je večji od 500km'); }

      if (popis.DmKonec - popis.DmZacetek < 0 && popis.LokType === '1') { errors.push('\nSeštevek dm ur je manjši od 0'); }

      // ker mašine ne kažejo prav oz se tanka 200l več v tank kot piše ne dam trenutno notr
      if (popis.LokType === '1') {
        const natocenoGorivo = +popis.GorivoNatoceno || 0;
        const poraba = popis.GorivoZacetek - popis.GorivoKonec + natocenoGorivo;
        if (poraba < 0) { errors.push(`\nPoraba goriva je manj kot 0: ${poraba} (začetek - konec + tankanje)`); }
        if (poraba > (popis.KmKonec - popis.KmZacetek) * 5.5 && popis.KmKonec) {
          const confPoraba = confirm('Zaznana je nadpovprečna poraba! Za ' + (popis.KmKonec - popis.KmZacetek) +
            'km je poraba ' + poraba + ' litrov.\nPotrdi vnos!');
          if (!confPoraba) { errors.push('\nNepotrjena nadpovprečna poraba.'); }
        }
        // pogleda če je št ur Dm znotraj tolerance porabljenega časa vlaka
        const dmTime = popis.DmKonec - popis.DmZacetek;
        // @ts-ignore
        const driveHours = new Date(popis.DatumPrihod - popis.DatumOdhod) / (60 * 60 * 1000);
        if (dmTime > driveHours + 10) { errors.push('\nPrevelik seštevek DM ur glede na prihod/odhod popisa'); }

      }
    }
    // če je tankanje
    if (popis.GorivoKraj || popis.GorivoNatoceno || popis.GorivoDm || popis.GorivoKm) {
      if (!popis.GorivoKraj) { errors.push('\nKraj tankanja manjka'); }
      if (popis.GorivoKraj.toLowerCase() === 'koper') { errors.push('\nTankanje za Koper vnaša pisarna LK'); }
      if (!popis.GorivoNatoceno) { errors.push('\nKoličina tankanja manjka'); }
      if (!popis.GorivoKm) { errors.push('\nKilometri pri tankanju manjkajo'); }
      if (!popis.GorivoDm) { errors.push('\nDizel motor (ure) pri tankanju manjkajo'); }
    }
    if (popis.GorivoDm !== null && popis.DmZacetek !== null && popis.DmKonec !== null) {
      if (popis.GorivoDm < popis.DmZacetek || popis.GorivoDm > popis.DmKonec) {
        errors.push('\nPri tankanju so trenutne DM ure izven popisanih vrednosti (manjše ali večje).'); }
    }

    if (popis.GorivoKm !== null && popis.KmZacetek !== null && popis.KmKonec !== null) {
      if (popis.GorivoKm < popis.KmZacetek || popis.GorivoKm > popis.KmKonec) {
        errors.push('\nPri tankanju so trenutni KM izven popisanih vrednosti (manjši ali večji)'); }
    }

    if (popis.GorivoNatoceno > 4200) { errors.push('\nPrevelika vrednost natankanega goriva.'); }

    if (errors.length > 0) { valid = false; }

    return {valid: valid, errors: errors};
  }

// *********** HTTP CALLS ***********
  getAllPopise() {
    return this.http.get<IPopisVlaka[]>(this.consts.domena + 'PopisVlakov/GetPopisAll/')
      .pipe(
        map(res => {
          res.forEach(popis => {
            popis.DatumOdhod = this.conversion.toDate(popis.DatumOdhod);
            popis.DatumPrihod = this.conversion.toDate(popis.DatumPrihod);
          });
          return res;
        })
      );
  }
  getAllByDate(postData: IPostByDate) {
    // POST - /PopisVlakov/GetPopisByDate/
    return this.http.post<IPopisVlaka[]>(this.consts.domena + 'PopisVlakov/GetPopisByDate/', postData)
      .pipe(map(data => {
        data.forEach(popis => {
          popis.DatumOdhod = this.conversion.toDate(popis.DatumOdhod);
          popis.DatumPrihod = this.conversion.toDate(popis.DatumPrihod);
        });
        return data;
      }));
  }

  getAllByYear(postData: IPostByYear) {
    return this.http.post<IPopisVlaka[]>(this.consts.domena + 'PopisVlakov/GetPopisByYear/', postData)
      .pipe(map(data => {
        data.forEach(popis => {
          popis.DatumOdhod = this.conversion.toDate(popis.DatumOdhod);
          popis.DatumPrihod = this.conversion.toDate(popis.DatumPrihod);
        });
        return data;
      }));
  }

  getPopisByLok(postData: IPostByLok) {
    return this.http.post<IPopisVlaka[]>(this.consts.domena + 'PopisVlakov/GetPopisLok/', postData)
      .pipe(map(data => {
        data.forEach(popis => {
          popis.DatumOdhod = this.conversion.toDate(popis.DatumOdhod);
          popis.DatumPrihod = this.conversion.toDate(popis.DatumPrihod);
        });
        data.sort((a, b) => (a.DatumOdhod > b.DatumOdhod) ? -1 : ((b.DatumOdhod > a.DatumOdhod) ? 1 : 0));
        return data;
      }));
  }

  getPopisByVlak(postData: IPostByVlak) {
    return this.http.post<IPopisVlaka[]>(this.consts.domena + 'PopisVlakov/GetPopisVlak/', postData)
      .pipe(map(data => {
        data.forEach(popis => {
          popis.DatumOdhod = this.conversion.toDate(popis.DatumOdhod);
          popis.DatumPrihod = this.conversion.toDate(popis.DatumPrihod);
        });
        return data;
      }));
  }

  addPopis(postData: IPopisVlaka) {
    return this.http.post<IPopisVlaka>(this.consts.domena + 'PopisVlakov/AddPopis/', postData);
  }

  editPopis(postData: IPopisVlaka) {
    return this.http.post<IPopisVlaka>(this.consts.domena + 'PopisVlakov/EditPopis/', postData);
  }

  // TODO: preštudiraj ali naj to returna boolean ali id da se izbriše iz front enda
  deletePopis(postData: IPostDelete) {
      return this.http.post<IPopisVlaka>(this.consts.domena + 'PopisVlakov/DeletePopisConfirmed/', postData);
    }


}
