import { Component, OnInit } from '@angular/core';
import { IPotniStroskiParsed } from 'src/app/rekapitulacija/potni-stroski.service';
import { ConstsService } from '../../consts.service';
import { PrintService } from '../print.service';

@Component({
  selector: 'app-print-potni-stroski',
  template: `
    <div style="height: 30px;">
      <img [src]="consts.domena + '/Content/pics/Logo_Adria_Transport.png'" height="25px" style="float: right;">
    </div>
    <hr>
    <div>
      <h3 style="margin-bottom: 15px;">ADRIA TRANSPORT, D.O.O.</h3>
      <p style="font-size: 10px;">Obračun za {{data.imePriimek}}</p>
      <p style="font-size: 10px;">Stroški za uporabo lastnega / javnega prevoznega sredstva v službene namene</p>
      <table class="table is-fullwidth is-borderes print" *ngIf="data">
          <th>Datum</th>
          <th>Relacija</th>
          <th>Km</th>
          <th>Cena</th>
        <tbody>
          <tr *ngFor="let ps of data.potniStroski">
            <td>{{ps.date | date: "dd.MM.yy"}}</td>
            <td>{{ps.relacija}}</td>
            <td>{{ps.km}}</td>
            <td>{{ps.cena.toFixed(2)}} € </td>
          </tr>
          <tr style="font-weight: bold;">
            <td></td>
            <td>Skupaj</td>
            <td>{{data.kmSkupaj}}</td>
            <td>{{data.cenaSkupaj.toFixed(2)}} €</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="float: left; margin-top: 10px; font-size: 10px">
      <div>
        Pregledala <br>
        Irena Muratović
      </div>
      <div>
        <div style="width: 85px; border-bottom: 1px solid black; margin-top: 30px;"></div>
      </div>
    </div>
    <div style="float: right; margin-top: 10px; font-size: 10px">
      <div>
        Direktor <br>
        Alen Kramberger
      </div>
      <div>
        <div style="width: 85px; border-bottom: 1px solid black; margin-top: 30px;"></div>
      </div>
    </div>
  `,
  styles: [
    `
    .print {
      color: black;
      font-size: 10px;
    }
    `
  ]
})
export class PrintPotniStroskiComponent implements OnInit {

  public data: IPotniStroskiParsed;
  constructor( private printService: PrintService, public consts: ConstsService) { }

  ngOnInit(): void {
    this.data = this.printService.potniStroskiData;
  }

}
