<div style="height: 100%;">
  <div style="text-align: center;">
    <h3 style="margin: 0.3rem;">Evidenca</h3>
    <h3 style="margin: 0.3rem">kilometerskega števca/ delovanja DM</h3>
    <div>
      <span>Mesec: {{ odhod | date: "LLLL" }}</span>
      <span style="margin: 2rem"></span>
      <span>Leto: {{ odhod.getFullYear() }}</span>
    </div>
  </div>
  <div>
    <table class="table-print" style="width: 90%; margin: 1rem auto;">
      <tr>
        <th>Datum</th>
        <th>Lokomotiva</th>
        <th>Kraj</th>
        <th>Natočena količina (l)</th>
        <th>Števec KM</th>
        <th>Števec DM (ure)</th>
      </tr>

      <tr *ngFor="let pop of _data">
        <td>{{ pop.DatumOdhod | date: "dd.MM.yy" }}</td>
        <td>{{ pop.Lokomotiva }}</td>
        <td>{{ pop.GorivoKraj }}</td>
        <td>{{ pop.GorivoNatoceno }}</td>
        <td>{{ pop.GorivoKm }}</td>
        <td>{{ pop.GorivoDm }}</td>
      </tr>
    </table>
  </div>
</div>
