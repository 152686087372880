import { Component } from '@angular/core';
import { PrintService } from './shared/print/print.service';
import { ConstsService } from './shared/consts.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ADTapp';

  constructor( public printService: PrintService, private constsService: ConstsService) {
    console.log('domena:', this.constsService.domena);
  }
}
