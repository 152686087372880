import { Component, OnInit } from '@angular/core';
import { IEvidenca } from 'src/app/evidence/evidence.service';
import { ConstsService } from '../../consts.service';
import { ConversionService } from '../../conversion.service';
import { PrintService } from '../print.service';

@Component({
  selector: 'app-print-evidenca-obracun',
  template: `
    <div style="height: 30px;">
      <img [src]="consts.domena + '/Content/pics/Logo_Adria_Transport.png'" height="25px" style="float: right;">
    </div>
    <hr>
    <div style="padding: 10px; font-size: 13px">
      <div style="margin-bottom: 5px;">
        <div>STROJEVODJA: <b>{{ evidenca.ime}}</b></div>
        <div>DATUM: {{ evidenca.datum }}</div>
      </div>

      <table class="table is-fullwidth is-bordered print" *ngIf="evidenca.urniki.length > 0">
        <th></th>
        <th *ngFor="let urnik of evidenca.urniki"> <div *ngFor="let vlak of urnik.vlaki">{{vlak}}</div></th>
        <th>SKUPAJ</th>
        <tbody>
          <tr>
            <td>Datum</td>
            <td *ngFor="let urnik of evidenca.urniki"> {{urnik.datum | date: "dd.MM.yy"}}</td>
            <td></td>
          </tr>
          <tr>
            <td>Nastop službe</td>
            <td *ngFor="let urnik of evidenca.urniki">{{urnik.nastopSluzbe | date: "HH:mm"}}</td>
            <td></td>
          </tr>
          <tr>
            <td>Konec izmene</td>
            <td *ngFor="let urnik of evidenca.urniki">{{urnik.konecIzmene | date: "HH:mm"}}</td>
            <td></td>
          </tr>
          <tr>
            <td>Trajanje izmene</td>
            <td *ngFor="let urnik of evidenca.urniki">{{conv.displayTrajanje(urnik.trajanjeIzmene)}}</td>
            <td>{{conv.displayTrajanje(evidenca.skupaj.izmena)}}</td>
          </tr>
          <tr>
            <td>Nočne ure</td>
            <td *ngFor="let urnik of evidenca.urniki">{{conv.displayTrajanje(urnik.nocneUre)}}</td>
            <td>{{conv.displayTrajanje(evidenca.skupaj.nocne)}}</td>
          </tr>
          <tr>
            <td>Nedeljske ure</td>
            <td *ngFor="let urnik of evidenca.urniki">{{conv.displayTrajanje(urnik.nedeljskeUre)}}</td>
            <td>{{conv.displayTrajanje(evidenca.skupaj.nedeljske)}}</td>
          </tr>
          <tr>
            <td>Praznične ure</td>
            <td *ngFor="let urnik of evidenca.urniki">{{conv.displayTrajanje(urnik.praznicneUre)}}</td>
            <td>{{conv.displayTrajanje(evidenca.skupaj.praznicne)}}</td>
          </tr>
          <tr>
            <td>Relacija</td>
            <td *ngFor="let urnik of evidenca.urniki"><div *ngFor="let rel of urnik.relacija">{{rel}}</div><td>
          </tr>
          <tr>
            <td>Km</td>
            <td *ngFor="let urnik of evidenca.urniki">
              <div *ngFor="let km of urnik.km">{{km}}</div>
              <div *ngFor="let km of urnik.kmSluzbeni">{{km}}</div>
            </td>
            <td>{{evidenca.skupaj.km}} ({{evidenca.skupaj.kmSluzbeni}}) - {{((evidenca.skupaj.km * 0.18) + (evidenca.skupaj.kmSluzbeni * 0.37)) | currency: 'EUR'}}</td>
          </tr>
          <tr>
            <td>Javni prevoz</td>
            <td *ngFor="let urnik of evidenca.urniki"><div *ngFor="let prevoz of urnik.javniPrevoz">{{prevoz | currency: 'EUR'}}</div></td>
            <td>{{evidenca.skupaj.javniPrevoz | currency: 'EUR'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <hr>
      <div style="display: flex; justify-content: center; font-size: 7px;">
        Adria transport, d.o.o. Vojkovo nabrežje 38 , 6000 Koper
      </div> 
    </div>
  `,
  styles: [
    `
    .print {
      color: black;
      font-size: 10px;
    }
    `
  ]
})
export class PrintEvidencaObracunComponent implements OnInit {

  public evidenca: IEvidenca;

  constructor( public consts: ConstsService, public conv: ConversionService, private printService: PrintService) { }

  ngOnInit(): void {
    this.evidenca = this.printService.evidencaData;
  }

}
