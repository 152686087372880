import {Component, OnDestroy, OnInit} from '@angular/core';
import { ConstsService } from '../../../shared/consts.service';
import {PopisLokomotivService} from '../../popis-lokomotiv.service';
import {UserService} from '../../../shared/user.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-izbira-lok',
  templateUrl: './izbira-lok.component.html',
  styleUrls: ['./izbira-lok.component.scss']
})
export class IzbiraLokComponent implements OnInit  {
  private loks;
  public loksToShow;
  public filterDloks = false;

  constructor(private consts: ConstsService, private popService: PopisLokomotivService, private user: UserService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loks = this.consts.lokomotive.slice();
    // TODO: preference in localstorage??
    this.filterLoks();
  }

  changeFilter() {
    this.filterDloks = !this.filterDloks;
    this.filterLoks();
  }

  filterLoks() {
    if (this.filterDloks) {
      this.loksToShow = this.loks.filter(l => l.lokType === 1);
    } else {
      this.loksToShow = this.loks;
    }
  }

  navigateToLocomotive(locomotive: string) {
    // different urls based on the current path
    const url = this.router.url;
    if (url.includes('dnevni-pregledi')) {
      this.router.navigate([locomotive], {relativeTo: this.route});
    } else {
      // trenutno se uporablja samo v popisih
      this.router.navigate(['l', locomotive], {relativeTo: this.route});
    }
  }




}
