import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IDnevniPregled } from 'src/app/dnevni-pregledi/dnevni-pregledi.service';
import { IEvidenca } from 'src/app/evidence/evidence.service';
import { IPopisVlaka } from 'src/app/popis-lokomotiv/shared/_models';
import { IPotniStrosekParsed, IPotniStroskiParsed } from 'src/app/rekapitulacija/potni-stroski.service';
import { IIzrednost, INepravilnost } from '../../porocila/_models';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  public isPrinting = false;
  public printData = [];
  public popisiLokData: IPopisVlaka[] = [];
  public porociloData: IIzrednost | INepravilnost;
  public dpData: IDnevniPregled;
  public evidencaData: IEvidenca;
  public potniStroskiData: IPotniStroskiParsed;

  constructor(private router: Router) {}

  startPrint(url: string) {
    this.isPrinting = true;
    this.router.navigate([
      '/',
      {
        outlets: {
          print: ['print', url]
        }
      }
    ]);
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    }, 250);
    // setTimeout(() => {
    //   this.isPrinting = false;
    // }, 5000);
  }

  public printPorocilo(data: INepravilnost | IIzrednost, url: string) {
    this.porociloData = data;
    this.startPrint(url);
  }

  public printDnevniPregled(data: IDnevniPregled, lokType: number) {
    this.dpData = data;
    this.dpData.LokType = lokType;
    this.startPrint('dnevni-pregled');
  }

  public printEvidenco(data: IEvidenca, url: string) {
    this.evidencaData = data;
    // url = 'evidenca-obracun' ali 'evidenca-voznje'
    this.startPrint(url);
  }

  public printPotneStroske(data: IPotniStroskiParsed) {
    this.potniStroskiData = data;
    this.startPrint('potni-stroski');
  }
}
