<mat-card style="margin-bottom: 1rem">
  <mat-form-field class="el" *ngIf="showZaposlene">
    <input type="text"
           placeholder="Zaposleni"
           aria-label="Zaposleni"
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto">
    <button mat-button *ngIf="myControl.value !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="clearZaposlenega()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let zaposleni of filteredZaposleni | async" [value]="zaposleni.ImePriimek" (click)="selectedZaposleni = zaposleni">
        {{zaposleni.ImePriimek}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="el" *ngIf="showDatum">
    <input matInput [matDatepicker]="picker" placeholder="{{showDatumTo ? 'Datum Od' : 'Datum'}}" disabled [(ngModel)]="date">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker disabled="false"></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="el" *ngIf="showDatumTo">
    <input matInput [matDatepicker]="picker" placeholder="Datum Do" disabled [(ngModel)]="dateTo">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker disabled="false"></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="el" *ngIf="showStringInput">
    <input type="text" matInput placeholder="Vrednost" [(ngModel)]="stringInput">
  </mat-form-field>

  <mat-form-field class="el" *ngIf="showMonthSelect">
    <mat-label>Mesec</mat-label>
    <mat-select [(ngModel)]="monthSelection">
      <mat-option value="1">Januar</mat-option>
      <mat-option value="2">Februar</mat-option>
      <mat-option value="3">Marec</mat-option>
      <mat-option value="4">April</mat-option>
      <mat-option value="5">Maj</mat-option>
      <mat-option value="6">Junij</mat-option>
      <mat-option value="7">Julij</mat-option>
      <mat-option value="8">Avgust</mat-option>
      <mat-option value="9">September</mat-option>
      <mat-option value="10">Oktober</mat-option>
      <mat-option value="11">November</mat-option>
      <mat-option value="12">December</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="izberi()" class="el">Izberi</button>
  <mat-checkbox [(ngModel)]="showAll" (ngModelChange)="filterZaposlene()" class="el" *ngIf="showZaposlene">Neaktivni</mat-checkbox>
  <mat-checkbox [(ngModel)]="showOnlyStrojevodje" (ngModelChange)="filterZaposlene()" class="el" *ngIf="showZaposlene">Samo strojevodje</mat-checkbox>
</mat-card>
