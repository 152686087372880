<div align="right">
  <img [src]="consts.domena + '/Content/pics/Logo_Adria_Transport.png'" height="20px">
</div>
<div align="center">
  <div>Poročilo o izrednosti</div>
  <div>Adria Transport d.o.o</div>
</div>
<img [src]="consts.domena + '/Content/pics/Logo_Adria_Transport.png'" height="20px">
<br>
<h4 style="text-align: center">DNEVNO POROČILO O IZREDNOSTI št. ({{formData.Id}})</h4>
<div style="margin-left: 30px">
  <div>Datum: <div class="underlinePrint" style="width: 150px" align="center">{{formData.Datum | date: 'dd.MM.yyyy'}}</div> Kraj dogodka: <div class="underlinePrint" style="width: 350px" align="center">{{formData.Kraj}}</div></div>
  <div>Št. vlaka: <div class="underlinePrint" style="width: 135px" align="center">{{formData.StVlak}}</div> Vlečno vozilo: <div class="underlinePrint" style="width: 350px" align="center">{{formData.VlakovnoVlecnoVozilo}}</div></div>
  <div>Čas dogodka: ura <div class="underlinePrint" style="width: 45px" align="center">{{formData.Datum | date: 'H'}}</div> min <div class="underlinePrint" style="width: 45px" align="center">{{formData.Datum | date: 'mm'}}</div> Masa vlaka: <div class="underlinePrint" style="width: 120px" align="center">{{formData.MasaVlaka}}</div>, št. osi: <div class="underlinePrint" style="width: 130px" align="center">{{formData.StOsi}}</div></div>
  <div>Strojevodja: <div class="underlinePrint" style="margin-top: 3px; width: 565px;" align="center">{{formData.ZaposleniName}}</div></div>
</div>

<div align="center" style="margin-top: 50px; margin-bottom: 15px">Opis dogodka - nepravilnosti</div>
<div style="display: flex; justify-content: center">
  <textarea class="mat-form-field" rows="20" style="min-width: 92%; resize: none; padding: 10px; border: black 2px solid;">{{formData.OpisDogodka}}</textarea>
</div>
<div style="margin-top: 50px" align="right">
  <div style="margin-right: 30px">Podpis: <img [src]="consts.domena + '/Content/podpisi/zaposleni_id_'+formData.ZaposleniId+'.png'" height="30px"></div>
</div>
<div style="margin-top: 50px; margin-right: 50px" align="right">
  EVVD 5
</div>
