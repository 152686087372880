import { Component, OnInit} from '@angular/core';
import {PrintService} from '../print.service';
import {IIzrednost, INepravilnost} from '../../../porocila/_models';
import {ConstsService} from '../../consts.service';

@Component({
  selector: 'app-print-nepravilnost',
  templateUrl: './print-nepravilnost.component.html',
  styleUrls: ['./print-nepravilnost.component.scss']
})
export class PrintNepravilnostComponent implements OnInit {
  formData: INepravilnost | IIzrednost;
  constructor(private printService: PrintService, public consts: ConstsService) { }

  ngOnInit(): void {
    this.formData = this.printService.porociloData;
  }

}
