import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IPopisVlaka } from "src/app/popis-lokomotiv/shared/_models";
import { PrintService } from "../print.service";

@Component({
  selector: "app-print-popisi-evidenca",
  templateUrl: "./print-popisi-evidenca.component.html",
  styleUrls: ["./print-popisi-evidenca.component.scss"]
})
export class PrintPopisiEvidencaComponent implements OnInit {
  public _data: IPopisVlaka[] = [];
  public odhod: Date;

  constructor(private printService: PrintService) {}

  ngOnInit(): void {
    this._data = this.printService.popisiLokData;
    this.odhod = this._data[0].DatumOdhod as Date;
  }
}
