import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private user: UserService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const tempReq = "DOrTKnfBlv_Zqr0HQxj39GGdZxVvUMzZOXl1rZTwvMqGPg3W6LG5chptqtLoEbUUPExISp_c5fP7aemT_YLDB50uW1zdlV1nhOOafvRJNASSPSOmLy6xo67A8J2moBcOazOVRg2";
        // const tempCookie = "__RequestVerificationToken=bcDCZZXRBJzVNWMrRIAXehSGootKQiynQrknhu3AUjuEwUCg-QLNz0hgywKtY3PJEOeFYgNOJc7Re8u33MiMhikL_Yo1; .AspNet.ApplicationCookie=bnMw_Vl2ZC9LKlEABqt8OXewMtwJ88ZFFwp0DT9o3pkH-THFw9DOiCgONgk7kfCHQGBQLUwqQQxC1qsww6fIL5oWPbwuy1gAKIQQuoHFq3bqwvZ4Z-4oNDu2Xg6n58PHQnS0XparmJFWPXfVkEgRJFUq08n3dEuFyF45n1XRh1w1mCZD8jvi61fi1bFqsMENGwtHnGB--BRIi4gUgNSN7ZeLACr6y2qGGA9CJgBxyvaP2kLkb65C2zb9eQPwvKiR_ur0kjSYs84Y9WZ8kESLCu02POvBLCWetsHnxSPFyvIcu3UvmzOavryNwTknbBORiyS3Q-_K4TKTRxdyFlX1spPfEO6-0ZqBWlp3xNgHQVl3aG3xJndLSD-BqdwxvHYXUMxkC0he6HQlHB445Sd4F0TTecFsHQqgQ3Qns1I6RbByw896dAlGyhWmADbmfnYvgP87wqEanqcEFVhymCdIxrqi2H96JGKH2o8DftYFqSS7Pz2aCBfC9Ylv1bWiU8PGSk538w";
        // const c1 = "qD7YGqLwL7CsFbbdoMscNscqwHBvym_RJLmc4h02lnanvj_fXNRcEoHCb30sShs6IuDbJw5UJ1yla50EKs8bF9R_dWk1";
        // const c2 = "inkDT_Zto_5v8a3Xfso0lzfnZZoHGvKioub11nUp3EHdRS8yubf1E0WbAzrsipEvkEdI6vqcVTAXw2ryj6wq1bzj7CGoE28SRS6C4BMIzbAJfcFwKhw5APxvwrajMLrOA76LmNmTvW5HGfQY9AkzrHwMp04yRZSSqJCP9_cPv4yXjqHQ2zHLW7uBliJrsIijHs4p2fJUT-xTaaBirsalO13-hsVJk833_BadK2wmSfbWqsks9a63mXuBUK8gGgcMlaGoLfCub0cz33v1g6ZH3YZxVDAuT4CmX5i2peXsdqorAeMHDN7IZU-APZoS-FQF8hF2H1FteapOJpdWG474fvsvB2ohgMcPC6Um304Lnk14TnR5Ac219imC8S--XiozNf58rF4ZfIUiVWUDWB0waIsIFqC8ni8DIBqDVfm0YsJdMGr1iS9NrWfNLG-JhAbEM7DIvELZg6hX1LlXf0RFY3wVFVYlx8c3iEbGahDVF4NsMATwhuQ7AMVogzrTf-XiLV_MrA";
        // document.cookie = 'Cookie=' + tempCookie + ';path=/';
        // document.cookie = 'Cookie=' + tempCookie + ';';
        console.log('interceptor Active!');

        // need to url-login?tkn..... to set tokens and setters and getters
        // const tempReq = sessionStorage.getItem('tkn');
        const tempReq = this.user.token;
        const headers = new HttpHeaders({
            '__RequestVerificationToken': tempReq,
            // 'Content-Type': 'application/json',
          // 'Cookie': 'ahoo=123',
        });

        // Chrome doesn't work ?? it doesn't send Cookie header no idea why...
        const reqClone = req.clone({ headers: headers, withCredentials: true });
        // const reqClone = req.clone({headers: headers});
    return next.handle(reqClone);
    }
}
