import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-napaka',
  templateUrl: './napaka.component.html',
  styleUrls: ['./napaka.component.scss']
})
export class NapakaComponent implements OnInit {

  constructor(title: Title) {
    title.setTitle('Napaka')
   }

  ngOnInit() {
  }

}
