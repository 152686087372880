import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-url-login',
  templateUrl: './url-login.component.html',
  styleUrls: ['./url-login.component.scss']
})
export class UrlLoginComponent implements OnInit {

  constructor( private auth: AuthService, private router: Router) { }

   ngOnInit() {
    this.auth.tempUrlCookieAndRole();
  }

}
