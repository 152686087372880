import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injectable } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './nav-bar/nav-bar.component';
  import { RoutingModule } from './modules/routing/routing.module';
import { PrijavaComponent } from './core/prijava/prijava.component';
import { NapakaComponent } from './core/napaka/napaka.component';
import { ConversionService } from './shared/conversion.service';
import { MaterialModule } from './shared/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConstsService } from './shared/consts.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/auth.interceptor';
import { AuthService } from './shared/auth.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { UserService } from './shared/user.service';
// import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { PrintService } from './shared/print/print.service';
import { PrintPopisiEvidencaComponent } from './shared/print/print-popisi-evidenca/print-popisi-evidenca.component';
import { PrintComponent } from './shared/print/print/print.component';
import { UrlLoginComponent } from './shared/url-login/url-login.component';
import '@angular/common/locales/global/sl';
import { NGX_MAT_DATE_FORMATS, NgxMatDateFormats, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { PrintNepravilnostComponent } from './shared/print/print-nepravilnost/print-nepravilnost.component';
import { PrintIzrednostComponent } from './shared/print/print-izrednost/print-izrednost.component';
import { SharedComponentsModule } from './modules/sharedComponents.module';
import { PrintDpComponent } from './shared/print/print-dp/print-dp.component';
import { PrintEvidencaVoznjeComponent } from './shared/print/print-evidenca-voznje/print-evidenca-voznje.component';
import { PrintEvidencaObracunComponent } from './shared/print/print-evidenca-obracun/print-evidenca-obracun.component';
import { PrintPotniStroskiComponent } from './shared/print/print-potni-stroski/print-potni-stroski.component';
import { LoadingComponent } from './shared/loading/loading.component';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit'
    }
  },
  display: {
    dateInput: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit'
    },
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    NapakaComponent,
    PrijavaComponent,
    PrintPopisiEvidencaComponent,
    PrintNepravilnostComponent,
    PrintIzrednostComponent,
    PrintComponent,
    UrlLoginComponent,
    PrintDpComponent,
    PrintEvidencaVoznjeComponent,
    PrintEvidencaObracunComponent,
    PrintPotniStroskiComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    // OverlayModule,
    NgxMatNativeDateModule,
    SharedComponentsModule,
    ],

  providers: [ConversionService,
    ConstsService,
    AuthService,
    UserService,
    PrintService,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'sl-SI' },
    { provide: LOCALE_ID, useValue: 'sl-SI' },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
  }

}
