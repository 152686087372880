import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConstsService {

  constructor(private http: HttpClient) {
  }

  public postaje = [
    'Anhovo',
    'Blanca',
    'Bled Jezero',
    'Bohinjska Bistrica',
    'Borovnica',
    'Breg',
    'Brestanica',
    'Brezovica',
    'Brežice',
    'Celje',
    'Celje t.',
    'Cirkovce Polje',
    'Cvetkovci',
    'Črnotiče',
    'Dankovci',
    'Divača',
    'Dobova',
    'Dolga Gora',
    'Gornje Ležeče',
    'Grahovo',
    'Grobelno',
    'Hoče',
    'Hodoš',
    'Hrastnik',
    'Hrastovlje',
    'Hr. Kozina',
    'Ilirska Bistrica',
    'Ivanjkovci',
    'Jesenice',
    'Kalsdorf',
    'Kidričevo',
    'Koper',
    'Koper t.',
    'Kranj',
    'Kresnice',
    'Krško',
    'Laško',
    'Laze',
    'Lesce-Bled',
    'Lipovci',
    'Litija',
    'Ljubljana',
    'Lj. CD Moste',
    'Lj. Moste',
    'Lj. Severna',
    'Lj. Šiška',
    'Lj. Vižmarje',
    'Lj. Zalog',
    'Ljutomer',
    'Logatec',
    'Maribor',
    'Maribor Tezno',
    'Medvode',
    'Most na Soči',
    'Moškanjci',
    'Murska Sobota',
    'Nova Gorica',
    'Ormož',
    'Pesnica',
    'Pivka',
    'Planina',
    'Podbrdo',
    'Podnart',
    'Poljčane',
    'Ponikva',
    'Postojna',
    'Pragersko',
    'Preserje',
    'Prestranek',
    'Prvačina',
    'Ptuj',
    'Rače',
    'Rakek',
    'Rimske Toplice',
    'Rižana',
    'Rodik',
    'Sava',
    'Sevnica',
    'Sežana',
    'Slovenska Bistrica',
    'Slovenski Javornik',
    'Spielfeld-Strass',
    'Šentilj',
    'Šentjur',
    'Škofja Loka',
    'Štanjel',
    'Trbovlje',
    'Trbovlje t.',
    'Verd',
    'Villa Opicina',
    'Volčja Draga',
    'Zagorje',
    'Zidani Most',
    'Žirovnica'
  ];

  public lokomotive = [
    { ime: '183-717', lokType: 0, aktivna: 1 },
    { ime: '190-312', lokType: 0, aktivna: 1 },
    { ime: '193-239', lokType: 0, aktivna: 1 },
    { ime: '193-268', lokType: 0, aktivna: 1 },
    { ime: '193-269', lokType: 0, aktivna: 1 },
    { ime: '193-271', lokType: 0, aktivna: 1 },
    { ime: '193-273', lokType: 0, aktivna: 1 },
    { ime: '193-279', lokType: 0, aktivna: 1 },
    { ime: '193-285', lokType: 0, aktivna: 1 },
    { ime: '193-286', lokType: 0, aktivna: 1 },
    { ime: '193-669', lokType: 0, aktivna: 1 },
    { ime: '193-670', lokType: 0, aktivna: 1 },
    { ime: '193-694', lokType: 0, aktivna: 1 },
    { ime: '193-697', lokType: 0, aktivna: 1 },
    { ime: '193-720', lokType: 0, aktivna: 1 },
    { ime: '193-722', lokType: 0, aktivna: 1 },
    { ime: '193-723', lokType: 0, aktivna: 1 },
    { ime: '193-730', lokType: 0, aktivna: 1 },
    { ime: '193-736', lokType: 0, aktivna: 1 },
    { ime: '193-741', lokType: 0, aktivna: 1 },
    { ime: '193-744', lokType: 0, aktivna: 1 },
    { ime: '193-746', lokType: 0, aktivna: 1 },
    { ime: '193-748', lokType: 0, aktivna: 1 },
    { ime: '193-752', lokType: 0, aktivna: 1 },
    { ime: '193-761', lokType: 0, aktivna: 1 },
    { ime: '193-765', lokType: 0, aktivna: 1 },
    { ime: '193-822', lokType: 0, aktivna: 1 },
    { ime: '193-839', lokType: 0, aktivna: 1 },
    { ime: '193-942', lokType: 0, aktivna: 1 },
    { ime: '193-957', lokType: 0, aktivna: 1 },
    { ime: '1216-920', lokType: 0, aktivna: 1 },
    { ime: '1216-921', lokType: 0, aktivna: 1 },
    { ime: '1216-922', lokType: 0, aktivna: 1 },
    { ime: '1216-960', lokType: 0, aktivna: 1 },
    { ime: '1223-003', lokType: 1, aktivna: 1 },
    { ime: '1223-005', lokType: 1, aktivna: 1 },
    { ime: '1223-007', lokType: 1, aktivna: 1 },
    { ime: '1223-010', lokType: 1, aktivna: 1 },
    { ime: '2016-903', lokType: 1, aktivna: 1 },
    { ime: '2016-904', lokType: 1, aktivna: 1 },
    { ime: '2016-905', lokType: 1, aktivna: 1 },
    { ime: '2016-906', lokType: 1, aktivna: 1 },
    { ime: '2016-909', lokType: 1, aktivna: 1 },
    { ime: '2016-920', lokType: 1, aktivna: 1 },
    { ime: '2016-921', lokType: 1, aktivna: 1 },
    { ime: '2016-922', lokType: 1, aktivna: 1 },
    { ime: '383-211', lokType: 0, aktivna: 1 },
    { ime: '383-212', lokType: 0, aktivna: 1 },
    { ime: '383-213', lokType: 0, aktivna: 1 },
    { ime: '383-214', lokType: 0, aktivna: 1 },
    { ime: '383-215', lokType: 0, aktivna: 1 },
    { ime: '383-216', lokType: 0, aktivna: 1 },
    { ime: '383-217', lokType: 0, aktivna: 1 },
    { ime: '383-218', lokType: 0, aktivna: 1 },
    { ime: '383-219', lokType: 0, aktivna: 1 },
    { ime: '383-220', lokType: 0, aktivna: 1 },
    { ime: '383-221', lokType: 0, aktivna: 1 },
    { ime: '383-222', lokType: 0, aktivna: 1 },
    { ime: '383-223', lokType: 0, aktivna: 1 },
    { ime: '383-301', lokType: 0, aktivna: 1 },
    { ime: '383-302', lokType: 0, aktivna: 1 },
  ];

  public holidays = {
    2020: [
        new Date(2020, 0, 1),
        new Date(2020, 0, 2),
        new Date(2020, 1, 8),
        new Date(2020, 3, 12),
        new Date(2020, 3, 13),
        new Date(2020, 3, 27),
        new Date(2020, 4, 1),
        new Date(2020, 4, 2),
        new Date(2020, 4, 31),
        new Date(2020, 5, 25),
        new Date(2020, 7, 15),
        new Date(2020, 9, 31),
        new Date(2020, 10, 1),
        new Date(2020, 11, 25),
        new Date(2020, 11, 26)],
    2021: [
        new Date(2021, 0, 1),
        new Date(2021, 0, 2),
        new Date(2021, 1, 8),
        new Date(2021, 3, 4),
        new Date(2021, 3, 5),
        new Date(2021, 3, 27),
        new Date(2021, 4, 1),
        new Date(2021, 4, 2),
        new Date(2021, 4, 23),
        new Date(2021, 5, 25),
        new Date(2021, 7, 15),
        new Date(2021, 9, 31),
        new Date(2021, 10, 1),
        new Date(2021, 11, 25),
        new Date(2021, 11, 26)],
    2022: [
        new Date(2022, 0, 1),
        new Date(2022, 0, 2),
        new Date(2022, 1, 8),
        new Date(2022, 3, 17),
        new Date(2022, 3, 18),
        new Date(2022, 3, 27),
        new Date(2022, 4, 1),
        new Date(2022, 4, 2),
        new Date(2022, 5, 5),
        new Date(2022, 5, 25),
        new Date(2022, 7, 15),
        new Date(2022, 9, 31),
        new Date(2022, 10, 1),
        new Date(2022, 11, 25),
        new Date(2022, 11, 26)],
    2023: [
        new Date(2022, 0, 1),
        new Date(2023, 0, 2),
        new Date(2023, 1, 8),
        new Date(2023, 3, 9),
        new Date(2023, 3, 10),
        new Date(2023, 3, 27),
        new Date(2023, 4, 1),
        new Date(2023, 4, 2),
        new Date(2023, 4, 28),
        new Date(2023, 5, 25),
        new Date(2023, 7, 15),
        new Date(2023, 9, 31),
        new Date(2023, 10, 1),
        new Date(2023, 11, 25),
        new Date(2023, 11, 26)],
  };

  public vrstaPotniStroski = [
    { vrsta: 0, opis: 'Osebni Avto' },
    { vrsta: 1, opis: 'Javni Prevoz' },
    { vrsta: 2, opis: 'Osebni Avto (Službeni Namen)' },
    { vrsta: 3, opis: 'Parkirnina' },
  ];

  public domena = isDevMode() ? 'https://dev.adt-app.si/' : 'https://adt-app.si/';

  public getLokomotivoByName(lok: string): ILokomotiva {
    let lokomotiva: ILokomotiva = null;
    this.lokomotive.forEach(l => {
      if (lok === l.ime) {
        lokomotiva = l;
      }
    });
    return lokomotiva;
  }

  public filterLoks(name: string) {
    return this.lokomotive.filter(lok => lok.ime.includes(name));
  }

  public getAllZaposlene() {
    return this.http.get(this.domena + 'Zaposlenis/GetAllZaposleni/');
  }

  public isPraznik(date: Date): boolean {
    const year = date.getFullYear();
    // rabi nujno preverjat samo do datuma.. če pride slučajno ura še notr
    const dateToCheck = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    let isPraznik = false;
    const prazniki = this.holidays[year];
    if (prazniki) {
      for (let i = 0; i < prazniki.length; i++) {
        const day = prazniki[i];
        if (+day === +dateToCheck) {
          isPraznik = true;
          break;
        }
      }
    }
    return isPraznik;
  }

  public fondUr(month: number, year: number, multiplier = 1): {fond, fondPraznik} {
    let fond = 0;
    let fondPraznik = 0;
    const nrDays = new Date(year, month + 1, 0).getDate();
    const prazniki = this.holidays[year];
    for (let i = 1; i <= nrDays; i++) {
      let isPraznik = 0;
      const day = new Date(year, month, i);
      if (day.getDay() !== 0 && day.getDay() !== 6) {
        // check if praznik
        praznik:
        for (let j = 0; j < prazniki.length; j++) {
          if (day.getTime() === prazniki[j].getTime()) {
            isPraznik = 1;
            break praznik;
          }
        }
        // doda ure v pravi sum
        if (!isPraznik) {
          fond += 8;
        } else {
          fondPraznik += 8;
        }
      }
    }

    fond *= multiplier;
    fondPraznik *= multiplier;
    return { fond, fondPraznik };
  }

  filterPostaje(name: string) {
    return this.postaje.filter(postaja => postaja.toLowerCase().includes(name.toLowerCase()));
  }
}

export interface ILokomotiva {
  ime: string;
  lokType: number;
  aktivna: number;
}
