import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { ConstsService } from './consts.service';
import { BreakpointObserver } from '@angular/cdk/layout';

function validateRole(role: string) {
  if (role === 'PogledSezana' || role === 'PogledStrojevodja' || role === 'PogledLk') {
    return 1;
  }
  return 0;
}



@Injectable()
export class AuthService {

  constructor(private route: ActivatedRoute, private router: Router, private user: UserService, private http: HttpClient,
              private consts: ConstsService) {
    console.log('Auth Created');
  }

  private _isAuthenticated = false;


  // eslint-disable-next-line max-len
  // TODO: če user nima pravih pooblastil modulu in proba v url kako stvar, ga log outa in preusmeri na prijavo -- ne vem če to spada sem ali v guard

  userAuthenticated() {
    // TODO: če user ni authenticated, redirecta na prijava
    // eslint-disable-next-line max-len
    // TODO: druga stvar, ku lahko tuki preveri je ali še velja jwt če je slučajno shranjen v cooks in če ni je avtomatsko false isAuthenticated in redirecta
    this._isAuthenticated = false;
  }

  login() {
    // TODO: login funkcija
    this._isAuthenticated = true;
  }

  logout() {
    // TODO: logout funkcija
    this._isAuthenticated = false;
  }

  redirectUserToLandingPage() {
    if (this._isAuthenticated) {
      // TODO: dobi prvi role in (če je str redirecta na str, hq redirecta na hq in lk na lk)
      this.router.navigate(['str']);
    }
  }

  // get cookie and role from url (until proper auth implemented)
  // This returns 1 if everything is ok or 0 if err
  public async tempUrlCookieAndRole() {
    if (document.cookie.length > 0) {
       await this.user.checkUser()
      // TODO: make sure
      return 1;

    }
    // Cookie: __RequestVerificationToken
    let c1 = '';
    // Cookie: .AspNet.ApplicationCookie
    let c2 = '';
    // aspnetuserid as c3=Mg== (base64):
    let userId = '';
    // tkn: token
    let tkn = '';
    let userName = '';
    // get the params
    this.route.queryParams.subscribe(params => {
      c1 = params['c1'];
      c2 = params['c2'];
      userId = atob(params['c3']);
      localStorage.setItem('c3', userId);
      userName = atob(params['c4']);
      tkn = params['tkn'];
      localStorage.setItem('tkn', tkn);
    });

    this.user.token = tkn;
    // time for expire is 14h from current time
    let now = new Date();
    // 840 minut je 14h
    now.setTime(now.getTime() + (840 * 60 * 1000));
    // debug 5 sec velja cookie
    // now.setTime(now.getTime() + (5 * 1000));
    console.log(now.toUTCString());



    document.cookie = `__RequestVerificationToken=${c1}; Expires=${now.toUTCString()}; Domain=.adt-app.si; SameSite=None; Secure`;
    document.cookie = `.AspNet.ApplicationCookie=${c2}; Expires=${now.toUTCString()}; Domain=.adt-app.si; SameSite=None; Secure`;




    // save to local storage and cookies
    // document.cookie = `__RequestVerificationToken=${c1}; Expires=${now.toUTCString()}; SameSite=None;Secure;`;
    // document.cookie = `.AspNet.ApplicationCookie=${c2}; Expires=${now.toUTCString()}; SameSite=None;Secure;`;
    // ČE je firefox
    // const userAgent = window.navigator.userAgent.toLowerCase();
    // console.log('User Agent:', userAgent);
    /*if (userAgent.includes('firefox')) {
      console.log('Firefox detected');
      document.cookie = `__RequestVerificationToken=${c1}; Domain=.adt-app.si; SameSite=None; Secure`;
      document.cookie = `.AspNet.ApplicationCookie=${c2}; Domain=.adt-app.si; SameSite=None; Secure`;
    } else if (userAgent.includes('chrome')) {
      console.log('Chrome detected');
      document.cookie = `__RequestVerificationToken=${c1};SameSite=None;Secure`;
      document.cookie = `.AspNet.ApplicationCookie=${c2};SameSite=None;Secure`;
    } else {
      // FIXME: rewrite if safar and firefox are the same. if chrome else other...
      console.log('not chrome or firefox');
      document.cookie = `__RequestVerificationToken=${c1}`;
      document.cookie = `.AspNet.ApplicationCookie=${c2}`;
    }*/

    let status = 0;
    // implement err
    if (c1 === undefined || c2 === undefined || userId === undefined || tkn === undefined)  {
      return status;
    }

    // validate role
    // status = validateRole(role);

    // if (status === 1) {
    //   this.user.currentRole = role;
    // //  /*/!* this*!/*/.user.token = tkn;
    // //   this.router.navigate(['']);
    // }
    if (userId !== '' && userName !== '') {
      // GET USER OBJECT FROM /Zaposlenis/GetZaposleni/{aspuserid}
      await this.http.get(this.consts.domena + 'Zaposlenis/GetZaposleni/' + userId).toPromise()
        .then((res: User[]) => {
          if (res.length === 1) {
            this.user.getUserFromZaposleniObject(res[0]);
            console.log('YAHOO::', this.user.name, userName);
            this.router.navigate(['']);
            status = 1;
          } else {
 document.location.href = 'https://adt-app.si';
}
        });
    }
    return status;
  }
}
