import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabelaRekapComponent } from './tabela-rekap/tabela-rekap.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    declarations: [TabelaRekapComponent],
    imports: [ CommonModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        FormsModule,
        MatExpansionModule,
        MatCardModule,
        MatTableModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        NgxNativeDateModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        ReactiveFormsModule
        ],
    exports: [
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        FormsModule,
        MatExpansionModule,
        MatCardModule,
        MatTableModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        NgxNativeDateModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        ReactiveFormsModule
    ],
    providers: [],
})
export class MaterialModule {}
